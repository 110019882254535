import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogContentText, TextField } from '@og-pro/ui';
import React, { useState } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';
import { isEmail } from 'validator';

export const ExportModal = ({ onClose, onSubmit, open, isPublic }) => {
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(null);
    const [token, setToken] = useState();

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const validateEmail = (e) => {
        setIsEmailValid(isEmail(e.target.value));
    };

    const handleClose = () => {
        setEmail('');
        setIsEmailValid(null);
        onClose();
    };

    const handleSubmit = () => {
        onSubmit(email, token);
        setEmail('');
        setIsEmailValid(null);
    };

    return (
        <Dialog
            dialogActions={
                <>
                    <Button color="secondary" onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        disabled={!token || !isEmailValid}
                        onClick={handleSubmit}
                        variant="contained"
                    >
                        Export
                    </Button>
                </>
            }
            dialogTitle="Export File"
            fullWidth
            maxWidth="sm"
            onClose={handleClose}
            open={open}
        >
            {isPublic ? (
                <>
                    <DialogContentText>
                        Exports may take time to complete depending on size and complexity. Please
                        provide an email to receive the file once ready to download.
                    </DialogContentText>
                    <Box
                        sx={{
                            '& .MuiTextField-root': { m: 0, pb: 1.5, px: 0, pt: 2, width: '100%' },
                        }}
                    >
                        <TextField
                            aria-label="Email"
                            error={isEmailValid === false}
                            helperText={isEmailValid === false ? 'Invalid email address' : ''}
                            inputProps={{
                                onBlur: validateEmail,
                            }}
                            label="Enter Email Address"
                            onChange={onEmailChange}
                            value={email}
                        />
                    </Box>
                    <Box pt={2}>
                        <Turnstile
                            onSuccess={setToken}
                            siteKey={process.env.CF_TURNSTILE_SITE_KEY}
                        />
                    </Box>
                </>
            ) : (
                <DialogContentText>
                    You will receive an email with a link to download the vendor list.
                </DialogContentText>
            )}
        </Dialog>
    );
};

ExportModal.propTypes = {
    isPublic: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};
