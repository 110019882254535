import { useCallback, useMemo } from 'react';
import { get, flatten, omit } from 'lodash';
import { useSelector } from 'react-redux';
import { sectionTypeNames } from '@og-pro/shared-config/sections';
import {
    questionLogicFieldNames,
    questionLogicLinkableModelNames,
} from '@og-pro/shared-config/questionLogics';

import { getQuestionLogicMap, getTemplateFormValues } from '../../../../TemplateEdit/selectors';
import { pseudoFieldNames } from '../../constants';

const { LINKABLE } = questionLogicFieldNames;
const { LINKABLE_ITEM } = pseudoFieldNames;
const {
    ATTACHMENTS,
    EVALUATION_CRITERIA,
    EVALUATION_PHASE,
    PRICING,
    QUESTIONNAIRE,
    SCOPE,
    SIGNATURE,
    TERMS,
    TEXT_AREA,
    INTRODUCTION,
} = sectionTypeNames;

const {
    ATTACHMENT: ATTACHMENT_LINKABLE,
    CRITERIA: CRITERIA_LINKABLE,
    PROJECT_SECTION: PROJECT_SECTION_LINKABLE,
    QUESTIONNAIRE: QUESTIONNAIRE_LINKABLE,
    SCORING_CRITERIA: SCORING_CRITERIA_LINKABLE,
    SIGNATURE_BLOCK: SIGNATURE_BLOCK_LINKABLE,
} = questionLogicLinkableModelNames;

/**
 * Returns a list of options to be used in the project section value select (the last one).
 * Depending on the selected section the options will be retrieved from a different place.
 * Additionally there's an "entire section" option.
 *
 * @param {number} linkableId
 * @param {object} modalFormData
 * @param {object} projectSection
 * @returns {array<object>}
 */
export const useProjectSectionValueSelectOptions = ({
    linkableId,
    modalFormData,
    projectSection,
}) => {
    // the questionLogicMap is used to determine what options are already in use to omit them
    const questionLogicMap = omit(useSelector(getQuestionLogicMap), linkableId);
    // the templateProjectFormValues is used to get the data from which we will assemble the options
    const templateProjectFormValues = useSelector(getTemplateFormValues);

    const generateOptions = useCallback((item, linkable, labelField) => {
        return {
            label: item[labelField] || 'Untitled',
            [LINKABLE]: linkable,
            [LINKABLE_ITEM]: item,
            value: `${linkable}.${item.id || item.sharedId}`, // Used to prevent potential value collisions between the linkable item ID and project section ID
        };
    }, []);

    const options = useMemo(() => {
        if (!projectSection) {
            return null;
        }

        const {
            attachments = [],
            criteria,
            evaluationPhases = [],
            questionnaires = [],
            signatures,
            standaloneSignatures = [],
        } = templateProjectFormValues;

        switch (projectSection.section_type) {
            case ATTACHMENTS:
                return attachments.map((attachment) =>
                    generateOptions(attachment, ATTACHMENT_LINKABLE, 'title')
                );
            case EVALUATION_CRITERIA:
            case EVALUATION_PHASE:
                return flatten(
                    evaluationPhases.map((evaluationPhase) => evaluationPhase.scoringCriteria)
                ).map((scoringCriterium) =>
                    generateOptions(scoringCriterium, SCORING_CRITERIA_LINKABLE, 'title')
                );
            case PRICING:
            case TEXT_AREA:
            case SIGNATURE: {
                let signature;

                // when this is being used in the Signatures tab, the signatures we are
                // using at the ones not related to any project section. That's why we pick it up
                // from the frontend variable standaloneSignatures
                if (modalFormData?.usingFakeSection) {
                    signature = standaloneSignatures[0];
                } else {
                    // if this is being used in a project section of type signature, we will have a project
                    // section and signatures are inside a map (similar to criteria items)
                    // that's why we grab them this way.
                    signature =
                        signatures[
                            `${projectSection.id}_${projectSection.projectSubsections[0].id}`
                        ];
                }

                // regardless of where the signature comes from, the process of getting the options
                // is the same
                return (signature?.signaturesBlocks || []).reduce((acc, cur, columnIndex) => {
                    const opts = cur.map((block, i) => {
                        return {
                            ...generateOptions(block, SIGNATURE_BLOCK_LINKABLE, 'title'),
                            label: `${columnIndex === 0 ? 'Left' : 'Right'} Column, Signature Block ${i + 1}`,
                        };
                    });

                    return acc.concat(opts);
                }, []);
            }
            case QUESTIONNAIRE:
                return questionnaires.map((question) =>
                    generateOptions(question, QUESTIONNAIRE_LINKABLE, 'title')
                );
            case SCOPE:
            case TERMS: {
                const projectSubsectionId = projectSection.projectSubsections[0].id;
                const selectedCriteria =
                    get(criteria, `${projectSection.id}_${projectSubsectionId}`) || [];
                return selectedCriteria.map((criterium) =>
                    generateOptions(criterium, CRITERIA_LINKABLE, 'title')
                );
            }
            case INTRODUCTION:
            default:
                return null;
        }
    }, [projectSection, modalFormData, templateProjectFormValues, generateOptions]);

    return useMemo(() => {
        if (!options) {
            return [];
        }

        // Add option for selecting entire section
        const entireSectionOption = {
            label: 'Entire section',
            [LINKABLE]: PROJECT_SECTION_LINKABLE,
            [LINKABLE_ITEM]: projectSection,
            // Used to prevent potential value collisions between the linkable item ID and project section ID
            value: `${PROJECT_SECTION_LINKABLE}.${projectSection.id}`,
            ...modalFormData?.entireSectionOptionOverrides,
        };

        return [entireSectionOption].concat(options).reduce((acc, option) => {
            // Remove items that have already had logic added to them
            if (get(questionLogicMap, option.value)) {
                return acc;
            }

            return acc.concat([option]);
        }, []);
    }, [options, modalFormData, projectSection, questionLogicMap]);
};
