import React from 'react';
import PropTypes from 'prop-types';
import { get, pick } from 'lodash';
import { Box } from '@og-pro/ui';
import { useDispatch, useSelector } from 'react-redux';
import { arrayMove, getFormSyncErrors, FieldArray } from 'redux-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { questionLogicLinkableModelNames } from '@og-pro/shared-config/questionLogics';

import { getDndStyle } from '../../../constants/styles';
import { DragIcon } from '../../DragIcon';
import { CDSDropdownButton } from '../../SDv2';
import { ContentBlock } from '../../SDv2/ContentBlock';
import { Button } from '../../Button';
import { MenuItem } from '../../MenuItem/MenuItem';
import { QuestionLogicIcon } from '../../../containers/GovApp/TemplateAdmin/components/QuestionLogicIcon';

import {
    blockFieldNames,
    defaultBlock,
    defaultSignatureHeaderBlock,
    fieldNames,
} from './constants';
import { SignatureField } from './Field';
import { SignatureHeader } from './Header';

const { SIGNATURE_BLOCK: SIGNATURE_BLOCK_LINKABLE } = questionLogicLinkableModelNames;
const { SIGNATURES_BLOCKS } = fieldNames;
const { FIELDS, PAGE_BREAK_AFTER, STYLING } = blockFieldNames;

const FieldsMap = ({
    fields,
    form,
    disabled,
    enableQuestionLogics,
    projectSection,
    questionLogicsConfig = null,
    showValidation,
    move,
}) => {
    const errors = useSelector((state) => get(getFormSyncErrors(form)(state), fields.name));
    const copyBlock = (index) => {
        const values = pick(fields.get(index), [FIELDS, PAGE_BREAK_AFTER, STYLING]);

        fields.push(values);
    };

    return (
        <>
            {fields.map((field, index) => (
                <Draggable draggableId={field} index={index} key={field}>
                    {(draggableProvided) => {
                        const block = fields.get(index);
                        const { styling } = block;

                        return (
                            <Box
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                            >
                                <ContentBlock error={get(errors, `${index}._error`)} withActions>
                                    <ContentBlock.Main disabled={disabled}>
                                        {styling === 'default' ? (
                                            <FieldArray
                                                component={SignatureField}
                                                disabled={disabled}
                                                form={form}
                                                index={index + 1}
                                                name={`${field}.${FIELDS}`}
                                                showValidation={showValidation}
                                            />
                                        ) : (
                                            <SignatureHeader
                                                disabled={disabled}
                                                name={`${field}.${FIELDS}`}
                                                showValidation={showValidation}
                                            />
                                        )}
                                    </ContentBlock.Main>
                                    <ContentBlock.ActionSidebar>
                                        {!!block.id && enableQuestionLogics && (
                                            <ContentBlock.ButtonGroup>
                                                <ContentBlock.Button>
                                                    <QuestionLogicIcon
                                                        linkable={SIGNATURE_BLOCK_LINKABLE}
                                                        linkableItem={block}
                                                        projectSection={projectSection}
                                                        {...questionLogicsConfig}
                                                    />
                                                </ContentBlock.Button>
                                            </ContentBlock.ButtonGroup>
                                        )}
                                        <ContentBlock.ButtonGroup>
                                            {index > 0 && (
                                                <ContentBlock.Button>
                                                    <Button
                                                        bsStyle="link"
                                                        onClick={() => move(index, index - 1)}
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="fa fa-arrow-up"
                                                        />
                                                    </Button>
                                                </ContentBlock.Button>
                                            )}
                                            <ContentBlock.Button>
                                                <DragIcon
                                                    dragHandleProps={
                                                        draggableProvided.dragHandleProps
                                                    }
                                                />
                                            </ContentBlock.Button>
                                            {index < fields.length - 1 && (
                                                <ContentBlock.Button>
                                                    <Button
                                                        bsStyle="link"
                                                        onClick={() => move(index, index + 1)}
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="fa fa-arrow-down"
                                                        />
                                                    </Button>
                                                </ContentBlock.Button>
                                            )}
                                        </ContentBlock.ButtonGroup>
                                        <ContentBlock.ButtonGroup>
                                            <ContentBlock.Button>
                                                <Button
                                                    bsStyle="link"
                                                    onClick={() => copyBlock(index)}
                                                >
                                                    <i aria-hidden="true" className="fa fa-copy" />
                                                </Button>
                                            </ContentBlock.Button>
                                        </ContentBlock.ButtonGroup>
                                        <ContentBlock.ButtonGroup>
                                            <ContentBlock.Button>
                                                <Button
                                                    bsStyle="link"
                                                    onClick={() => fields.remove(index)}
                                                >
                                                    <i aria-hidden="true" className="fa fa-trash" />
                                                </Button>
                                            </ContentBlock.Button>
                                        </ContentBlock.ButtonGroup>
                                    </ContentBlock.ActionSidebar>
                                </ContentBlock>
                            </Box>
                        );
                    }}
                </Draggable>
            ))}
        </>
    );
};
FieldsMap.propTypes = {
    disabled: PropTypes.bool,
    enableQuestionLogics: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    move: PropTypes.func.isRequired,
    projectSection: PropTypes.object,
    questionLogicsConfig: PropTypes.object,
    showValidation: PropTypes.bool,
};

export const SignatureBlock = ({
    disabled,
    enableQuestionLogics,
    fields,
    form,
    projectSection,
    questionLogicsConfig = null,
    showValidation,
}) => {
    const dispatch = useDispatch();

    const move = (originLocation, newLocation) => {
        if (newLocation !== undefined && newLocation !== originLocation) {
            dispatch(arrayMove(form, fields.name, originLocation, newLocation));
        }
    };

    const handleDragEnd = (result) => {
        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        move(originLocation, newLocation);
    };

    return (
        <Box>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                    droppableId="signatureBlockList"
                    isDropDisabled={disabled}
                    type={SIGNATURES_BLOCKS}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getDndStyle(snapshot)}
                            {...provided.droppableProps}
                        >
                            <FieldsMap
                                fields={fields}
                                move={move}
                                {...{
                                    disabled,
                                    enableQuestionLogics,
                                    questionLogicsConfig,
                                    showValidation,
                                    projectSection,
                                    form,
                                }}
                            />

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Box pb={2}>
                <CDSDropdownButton
                    disabled={disabled}
                    size="small"
                    title={
                        <>
                            <i className="fa fa-plus" /> Add Signature Block
                        </>
                    }
                    variant="secondary"
                >
                    <MenuItem
                        onClick={() => fields.push(defaultBlock)}
                        qaTag="signatureForm-addBlock"
                    >
                        Signature Block
                    </MenuItem>
                    <MenuItem
                        onClick={() => fields.unshift(defaultSignatureHeaderBlock)}
                        qaTag="timelineForm-addEvent"
                    >
                        Signature Header
                    </MenuItem>
                </CDSDropdownButton>
            </Box>
        </Box>
    );
};

SignatureBlock.propTypes = {
    disabled: PropTypes.bool,
    enableQuestionLogics: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    projectSection: PropTypes.object,
    questionLogicsConfig: PropTypes.object,
    showValidation: PropTypes.bool,
};
