import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, formValueSelector } from 'redux-form';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@og-pro/ui';

import { SignatureBlock } from './Block';
import { CDSButtonGroup, CDSButton } from '../../SDv2';
import { ContentBlock } from '../../SDv2/ContentBlock';
import { RichTextInput } from '../../RichTextInput';
import { SearchSelect } from '../../SearchSelect/SearchSelect';
import { fieldNames, stampOptions } from './constants';
import ActionSideBarForTextBlocks from './ActionSideBarForTextBlocks';

const { BLANK_BLOCKS, FOOTER, INTRODUCTION } = fieldNames;

export const SignatureForm = ({
    change,
    disabled,
    enableQuestionLogics = false,
    form,
    formKey,
    projectSection,
    showValidation,
    questionLogicsConfig = null,
    tagOptions,
    templateVariableOptions,
}) => {
    const blankBlocks = useSelector((state) =>
        formValueSelector(form)(state, `${formKey}.${BLANK_BLOCKS}`)
    );
    const [{ showIntroduction, showFooter }, setShowFields] = useState({
        showIntroduction: false,
        showFooter: false,
    });
    const { introduction, footer } = useSelector((state) => {
        const selector = formValueSelector(form);

        return {
            introduction: selector(state, `${formKey}.${INTRODUCTION}`),
            footer: selector(state, `${formKey}.${FOOTER}`),
        };
    });
    const styles = require('./index.scss');

    const toggleIntroduction = () => {
        change(`${formKey}.${INTRODUCTION}`, '');
        setShowFields((s) => ({ ...s, showIntroduction: !s.showIntroduction }));
    };
    const toggleFooter = () => {
        change(`${formKey}.${FOOTER}`, '');
        setShowFields((s) => ({ ...s, showFooter: !s.showFooter }));
    };
    const addNotaryBlock = () => {
        change(`${formKey}.${BLANK_BLOCKS}`, [{ value: '5in' }]);
    };
    const removeNotaryBlock = () => {
        change(`${formKey}.${BLANK_BLOCKS}`, []);
    };

    useEffect(() => {
        setShowFields({
            showIntroduction: !!introduction,
            showFooter: !!footer,
        });
    }, []);

    return (
        <Box className={styles.container}>
            {!showIntroduction && (
                <CDSButtonGroup>
                    <CDSButton
                        onClick={toggleIntroduction}
                        qaTag="signatureForm-addIntroduction"
                        size="small"
                        variant="secondary-alt"
                    >
                        <i className="fa fa-plus" /> Add Introductory Text
                    </CDSButton>
                </CDSButtonGroup>
            )}
            {showIntroduction && (
                <ContentBlock withActions>
                    <ContentBlock.Main disabled={disabled}>
                        <Box className={classnames(styles.label)}>Introductory Text</Box>
                        <Box className={classnames(styles.description)}>
                            This can be used for a predefined clause, label, or text snippet as an
                            introduction to all signatures.
                        </Box>
                        <Box>
                            <Field
                                borderless
                                component={RichTextInput}
                                disabled={disabled}
                                label={null}
                                minRows={3}
                                name={`${formKey}.${INTRODUCTION}`}
                                placeholder="Enter Text"
                                showValidation={showValidation}
                                tagOptions={tagOptions}
                                templateVariableOptions={templateVariableOptions}
                                useOpenGovStyle
                                useSharedTextareaToolbar
                            />
                        </Box>
                    </ContentBlock.Main>
                    <ActionSideBarForTextBlocks trashAction={toggleIntroduction} />
                </ContentBlock>
            )}

            <Grid container spacing={6}>
                <Grid item sm={6} xs={12}>
                    <Box className={styles.blockColumn}>
                        <FieldArray
                            component={SignatureBlock}
                            disabled={disabled}
                            enableQuestionLogics={enableQuestionLogics}
                            form={form}
                            name={`${formKey}.signaturesBlocks[0]`}
                            projectSection={projectSection}
                            questionLogicsConfig={questionLogicsConfig}
                            showValidation={showValidation}
                        />
                    </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Box className={styles.blockColumn}>
                        <FieldArray
                            component={SignatureBlock}
                            disabled={disabled}
                            enableQuestionLogics={enableQuestionLogics}
                            form={form}
                            name={`${formKey}.signaturesBlocks[1]`}
                            projectSection={projectSection}
                            questionLogicsConfig={questionLogicsConfig}
                            showValidation={showValidation}
                        />
                    </Box>
                </Grid>
            </Grid>
            {!blankBlocks?.length && (
                <Box>
                    <CDSButtonGroup>
                        <CDSButton
                            onClick={() => addNotaryBlock()}
                            qaTag="signatureForm-addNotaryBlock"
                            size="small"
                            variant="secondary-alt"
                        >
                            <i className="fa fa-plus" /> Add Notary
                        </CDSButton>
                    </CDSButtonGroup>
                </Box>
            )}
            {!!blankBlocks?.length && (
                <ContentBlock withActions>
                    <ContentBlock.Main disabled={disabled}>
                        <Box className={classnames(styles.label)}>Notary Block</Box>
                        <Box
                            className={classnames(
                                styles.description,
                                styles.descriptionMarginBottom
                            )}
                        >
                            This can be used as a placeholder for notary or you can also create any
                            text customization within this space, if you want to insert something
                            into the signature page that is outside of OpenGov&apos;s formatting.
                        </Box>
                        <Box height={`calc(${blankBlocks[0].value} / 2)`} width="200px">
                            <Field
                                component={SearchSelect}
                                disabled={disabled}
                                hasFeedback={false}
                                label={null}
                                name={`${formKey}.${BLANK_BLOCKS}[0].value`}
                                options={stampOptions}
                                showValidation={showValidation}
                                useOpenGovStyle
                            />
                        </Box>
                    </ContentBlock.Main>
                    <ActionSideBarForTextBlocks trashAction={removeNotaryBlock} />
                </ContentBlock>
            )}
            {!showFooter && (
                <CDSButtonGroup>
                    <CDSButton
                        onClick={toggleFooter}
                        qaTag="signatureForm-addFooter"
                        size="small"
                        variant="secondary-alt"
                    >
                        <i className="fa fa-plus" /> Add Concluding Text
                    </CDSButton>
                </CDSButtonGroup>
            )}
            {showFooter && (
                <ContentBlock withActions>
                    <ContentBlock.Main disabled={disabled}>
                        <Box className={classnames(styles.label)}>Concluding Text</Box>
                        <Box
                            className={classnames(
                                styles.description,
                                styles.descriptionMarginBottom
                            )}
                        >
                            The concluding text can be used for a predefined clause, label, or text
                            snippet as an conclusion to all signatures.
                        </Box>
                        <Box>
                            <Field
                                borderless
                                component={RichTextInput}
                                disabled={disabled}
                                label={null}
                                minRows={3}
                                name={`${formKey}.${FOOTER}`}
                                placeholder="Enter Text"
                                showValidation={showValidation}
                                tagOptions={tagOptions}
                                templateVariableOptions={templateVariableOptions}
                                useOpenGovStyle
                                useSharedTextareaToolbar
                            />
                        </Box>
                    </ContentBlock.Main>
                    <ActionSideBarForTextBlocks trashAction={toggleFooter} />
                </ContentBlock>
            )}
        </Box>
    );
};

SignatureForm.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    enableQuestionLogics: PropTypes.bool,
    form: PropTypes.string.isRequired,
    formKey: PropTypes.string.isRequired,
    projectSection: PropTypes.object.isRequired,
    questionLogicsConfig: PropTypes.object,
    showValidation: PropTypes.bool,
    tagOptions: PropTypes.array,
    templateVariableOptions: PropTypes.array,
};
