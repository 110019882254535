import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import {
    questionLogicFieldNames,
    questionLogicOperatorNames,
    questionLogicLogicableModelAlias,
} from '@og-pro/shared-config/questionLogics';

import { actionSelectOptions, form, pseudoFieldNames } from './constants';
import {
    getInitialFormValues,
    getProjectSectionSelectionOptions,
    getProjectSectionValue,
    getProjectSectionValueSelectOptions,
    getUpfrontQuestionSelectOptions,
    getUpfrontQuestionValue,
    getUpfrontQuestionValueSelectOptions,
    hasMultiSelectOperator,
} from './selectors';
import { UpfrontQuestionSelectOption } from './UpfrontQuestionSelectOption';
import { serializeData } from './utils';
import { validate } from './validate';
import {
    questionLogicConfirmExitMessage as confirmExitMessage,
    operatorSelectOptions,
} from '../../../constants';
import { hideQuestionLogicModal } from '../../../../../actions/templatesAdmin';
import {
    Button,
    OutlineButton,
    SearchSelect,
    SearchSelectIconOption,
    SearchSelectIconValue,
} from '../../../../../components';
import { QuestionLogicModal as NewQuestionLogicModal } from './v2';

const { ACTION, LINKABLE, LINKABLE_ID, OPERATOR, VALUE, LOGICABLE_ID } = questionLogicFieldNames;

const { LINKABLE_ITEM, PROJECT_SECTION, PROJECT_SECTION_ID, UPFRONT_QUESTION } = pseudoFieldNames;

const { EQUAL, INCLUDE, NOT_EQUAL, NOT_INCLUDE } = questionLogicOperatorNames;

const mapStateToProps = (state, props) => {
    const initialValues = getInitialFormValues(state);
    const editIndex = state.templatesAdmin.get('questionLogicEditIndex');
    const disableLinkableItem = state.templatesAdmin.get('questionLogicLinkableItemDisabled');

    return {
        disableLinkableItem,
        editIndex,
        hasConditionalMultiSelectValue: hasMultiSelectOperator(state),
        hasProjectSectionValue: !!getProjectSectionValue(state),
        hasUpfrontQuestionValue: !!getUpfrontQuestionValue(state),
        initialValues,
        isEditForm: !!editIndex || editIndex === 0,
        projectSectionSelectOptions: getProjectSectionSelectionOptions(state, props),
        projectSectionValueSelectOptions: getProjectSectionValueSelectOptions(state, props),
        upfrontQuestionSelectOptions: getUpfrontQuestionSelectOptions(state, props),
        upfrontQuestionValueSelectOptions: getUpfrontQuestionValueSelectOptions(state, props),
    };
};

const mapDispatchToProps = {
    hideModal: hideQuestionLogicModal,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedQuestionLogicModal extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        dirty: PropTypes.bool.isRequired,
        disableLinkableItem: PropTypes.bool.isRequired,
        editIndex: PropTypes.number,
        hasConditionalMultiSelectValue: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        hasProjectSectionValue: PropTypes.bool.isRequired,
        hasUpfrontQuestionValue: PropTypes.bool.isRequired,
        hideModal: PropTypes.func.isRequired,
        isEditForm: PropTypes.bool,
        onAdd: PropTypes.func.isRequired,
        onEdit: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        projectSections: PropTypes.array.isRequired, // eslint-disable-line react/no-unused-prop-types
        projectSectionSelectOptions: PropTypes.array.isRequired,
        projectSectionValueSelectOptions: PropTypes.array.isRequired,
        questionType: PropTypes.shape({
            type: PropTypes.string.isRequired,
        }),
        upfrontQuestions: PropTypes.array.isRequired, // eslint-disable-line react/no-unused-prop-types
        upfrontQuestionSelectOptions: PropTypes.array.isRequired,
        upfrontQuestionValueSelectOptions: PropTypes.array.isRequired,
    };

    hideModal = (options = {}) => {
        const { dirty, hideModal } = this.props;
        const { force } = options;

        // Confirm exit if form is dirty and not forcing hide
        // eslint-disable-next-line no-alert
        if (force || !dirty || window.confirm(confirmExitMessage)) {
            hideModal();
        }
    };

    onChangeWithOptionLinkableItemHandler = (option) => {
        const { change } = this.props;

        if (!option) {
            change(LINKABLE, null);
            change(LINKABLE_ITEM, null);
            return;
        }

        change(LINKABLE, option[LINKABLE]);
        change(LINKABLE_ITEM, option[LINKABLE_ITEM]);
    };

    onChangeWithOptionProjectSectionHandler = (option) => {
        const { change } = this.props;

        if (!option) {
            change(PROJECT_SECTION, null);
            return;
        }

        change(PROJECT_SECTION, option[PROJECT_SECTION]);
    };

    onChangeWithOptionUpfrontQuestionHandler = (option) => {
        const { change } = this.props;

        if (!option) {
            change(UPFRONT_QUESTION, null);
            return;
        }

        // this is temporary until the form saves and we get the right values from the backend
        // until then, this matches the logicable alias
        change(questionLogicLogicableModelAlias.UPFRONT_QUESTION, option[UPFRONT_QUESTION]);
    };

    operatorChangeHandler = (e, newValue, prevValue) => {
        const { change } = this.props;

        const VALUE_TYPE = {
            [EQUAL]: 'single',
            [NOT_EQUAL]: 'single',
            [INCLUDE]: 'multi',
            [NOT_INCLUDE]: 'multi',
        };

        if (VALUE_TYPE[newValue] !== VALUE_TYPE[prevValue]) {
            change(VALUE, null);
        }
    };

    projectSectionChangeHandler = () => {
        const { change } = this.props;

        change(LINKABLE_ID, null);
    };

    removeHandler = () => {
        const { editIndex, onRemove } = this.props;

        onRemove(editIndex, () => this.hideModal({ force: true }));
    };

    submitHandler = (data) => {
        const { editIndex, isEditForm, onAdd, onEdit } = this.props;

        const serializedData = serializeData(data);

        if (isEditForm) {
            onEdit(serializedData, editIndex);
        } else {
            onAdd(serializedData);
        }

        this.hideModal({ force: true });
    };

    upfrontQuestionChangeHandler = () => {
        const { change } = this.props;

        change(VALUE, null);
    };

    renderIconOption(option) {
        return (
            <span>
                <i className={`fa fa-fw fa-${option.icon}`} /> {option.label}
            </span>
        );
    }

    renderNoUpfrontQuestion() {
        const { hideModal } = this.props;

        return (
            <Modal bsSize="sm" onHide={hideModal} show>
                <Modal.Header closeButton />
                <Modal.Body className="text-center">
                    <i className="fa fa-3x fa-magic text-primary" />
                    <h4 style={{ margin: '10px 0px' }}>No Document Set up Question</h4>
                    <p>
                        To add automation first add at least one Document Set Up Question that can
                        be used here.
                    </p>
                </Modal.Body>
            </Modal>
        );
    }

    render() {
        const {
            disableLinkableItem,
            handleSubmit,
            hasConditionalMultiSelectValue,
            hasProjectSectionValue,
            hasUpfrontQuestionValue,
            isEditForm,
            projectSectionSelectOptions,
            projectSectionValueSelectOptions,
            upfrontQuestionSelectOptions,
            upfrontQuestionValueSelectOptions,
        } = this.props;

        if (upfrontQuestionSelectOptions.length === 0) {
            return this.renderNoUpfrontQuestion();
        }

        const modalTitle = `${isEditForm ? 'Edit' : 'Add'} Automation Logic`;
        const disableLinkableItemSelection = disableLinkableItem;

        return (
            <Modal bsSize="lg" onHide={this.hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-xs-12">
                            <label>When the following condition is met</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-4">
                            <Field
                                aria-label="Configuration Question"
                                component={SearchSelect}
                                components={{
                                    Option: UpfrontQuestionSelectOption,
                                }}
                                name={LOGICABLE_ID}
                                onChange={this.upfrontQuestionChangeHandler}
                                onChangeWithOption={this.onChangeWithOptionUpfrontQuestionHandler}
                                options={upfrontQuestionSelectOptions}
                                placeholder="Configuration Question"
                            />
                        </div>
                        <div className="col-xs-4">
                            <Field
                                aria-label="Operator"
                                component={SearchSelect}
                                name={OPERATOR}
                                onChange={this.operatorChangeHandler}
                                options={operatorSelectOptions}
                                placeholder="Operator"
                            />
                        </div>
                        <div className="col-xs-4">
                            <Field
                                aria-label="Value"
                                component={SearchSelect}
                                disabled={!hasUpfrontQuestionValue}
                                help={
                                    hasUpfrontQuestionValue ? undefined : 'Select a question first'
                                }
                                isMulti={hasConditionalMultiSelectValue}
                                isMultiSimpleValue={hasConditionalMultiSelectValue}
                                name={VALUE}
                                options={upfrontQuestionValueSelectOptions}
                                placeholder="Value"
                                useNullWhenUndefined
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="text-center">
                                <i className="fa fa-arrow-down fa-2x text-primary" />
                            </div>
                            <label>Take the following action</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-4">
                            <Field
                                aria-label="Action"
                                component={SearchSelect}
                                components={{
                                    Option: SearchSelectIconOption,
                                    SingleValue: SearchSelectIconValue,
                                }}
                                name={ACTION}
                                options={actionSelectOptions}
                                placeholder="Action"
                            />
                        </div>
                        <div className="col-xs-4">
                            <Field
                                aria-label="Section"
                                component={SearchSelect}
                                disabled={disableLinkableItemSelection}
                                name={PROJECT_SECTION_ID}
                                onChange={this.projectSectionChangeHandler}
                                onChangeWithOption={this.onChangeWithOptionProjectSectionHandler}
                                options={projectSectionSelectOptions}
                                placeholder="Section"
                            />
                        </div>
                        <div className="col-xs-4">
                            <Field
                                aria-label="Section Item"
                                component={SearchSelect}
                                disabled={!hasProjectSectionValue || disableLinkableItemSelection}
                                help={hasProjectSectionValue ? undefined : 'Select a section first'}
                                name={LINKABLE_ID}
                                onChangeWithOption={this.onChangeWithOptionLinkableItemHandler}
                                options={projectSectionValueSelectOptions}
                                placeholder="Section Item"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 text-right">
                            {isEditForm && (
                                <>
                                    <OutlineButton
                                        bsSize="sm"
                                        bsStyle="danger"
                                        onClick={this.removeHandler}
                                    >
                                        <i className="fa fa-trash" /> Remove Logic
                                    </OutlineButton>
                                    &nbsp;&nbsp;
                                </>
                            )}
                            <Button
                                bsStyle={isEditForm ? 'primary' : 'success'}
                                onClick={handleSubmit(this.submitHandler)}
                            >
                                <i className={`fa fa-${isEditForm ? 'pencil' : 'plus'}`} />
                                &nbsp;
                                {isEditForm ? 'Done' : 'Add Logic'}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export const LegacyQuestionLogicModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedQuestionLogicModal);

export const QuestionLogicModal = (props) => {
    const newModalEnabled = useFlags(FLAGS.ENABLE_REFACTORED_QUESTION_LOGIC_MODAL);

    if (newModalEnabled) {
        return <NewQuestionLogicModal {...props} />;
    }

    return <LegacyQuestionLogicModal {...props} />;
};
